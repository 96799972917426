import _ from 'lodash'
import jobGroup from '@/services/jobGroup';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";

const getDefaultState = () => {
  return {
    jobGroups: [],
  };
}

const state = getDefaultState()

const getters = {
  getJobGroups: state => state.jobGroups,
};

const mutations = {
  ["SET_JOB_GROUPS"](state, payload) {
    state.jobGroups = payload;
  }
};

const actions = {
  fetchJobGroups: ({ commit, getters, dispatch }) => {
    const { getCustomerId, isUKMainAgency, getIsParentUserFromAccessToken, getOrgIDFromAccessToken } = getters
    let payload = {
      customer_uid: getCustomerId,
      all_rows: true
    }
    if (!isUKMainAgency && !getIsParentUserFromAccessToken) {
      payload.organisation_id = getOrgIDFromAccessToken
    }
    let query = getFilterQueryStringWithoutArray(payload);
    return jobGroup
      .fetchJobGroups(query)
      .then(res => {
        commit("SET_JOB_GROUPS", res.data);
        commit("SET_JOB_GROUP_LIST", res.data);
        dispatch("initAllFilters")
        return res;
      })
      .catch(err => {
        console.log("error fetching job groups", err);
        return err;
      });
  },
  createJobGroup: ({ dispatch }, payload) => {
    return jobGroup
      .createJobGroup(payload)
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Job Group added successfully'
        })
        dispatch("fetchJobGroups");
      })
      .catch(err => {
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Job Group already exists'
        })
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  updateJobGroup: ({ dispatch }, payload) => {
    return jobGroup
      .updateJobGroup(payload)
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Group updated successfully'
        })
        dispatch("fetchJobGroups");
      })
      .catch(err => {
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Job Group already exists'
        })
        console.log("error while updating job group", err);
        return err;
      });
  },
  deleteJobGroup: ({ dispatch }, group_id) => {
    return jobGroup
      .deleteJobGroup(group_id)
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Group deleted successfully'
        })
        dispatch("fetchJobGroups");
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
